import React from "react";
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";
import "./IframeContainer.css";

const IframeContainer = ({
  url,
  loading,
  setLoading,
  onClose,
  config,
  buttonUnique,
  widgetType,
  buttonStyle,
  fullSize, // Agregamos fullSize como prop
}) => {
  const isVerticalV2 = widgetType === "vertical-v2";
  const isCircular = widgetType === "circular";
  const isHorizontal = widgetType === "horizontal";
  let buttonsQuantity;

  console.log("WidgetType en iframe: ", widgetType);
  if (widgetType === "vertical") {
    console.log(config.buttons.length);
    console.log("Antes del switch");
    switch (config.buttons.length) {
      case 1:
        buttonsQuantity = `one-button`;
        break;
      case 2:
        buttonsQuantity = "two-buttons";
        break;
      case 3:
        buttonsQuantity = "three-buttons";
        break;
      case 4:
        buttonsQuantity = "four-buttons";
        break;
      case 5:
        buttonsQuantity = "five-buttons";
        break;
      default:
        break;
    }
  }

  return (
    <div
      className={`iframe-container${
        isCircular
          ? "-circular"
          : isVerticalV2
          ? "-v2"
          : isHorizontal
          ? "-horizontal"
          : ""
      } ${buttonUnique ? "unique-button" : ""} ${
        loading ? "show-spinner" : "fade-in"
      } iframe-${buttonStyle} ${
        fullSize ? "expanded-buttons" : "not-expanded-buttons"
      }  ${buttonsQuantity}`} // Usamos expanded-buttons
      style={{
        position: "absolute",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <iframe
        src={url}
        title="Widget iframe"
        onLoad={() => setLoading(false)}
        className={`iframeMitrol ${loading ? "hidden" : "fade-in"} ${
          fullSize ? "expanded-buttons" : ""
        }`} // Usamos expanded-buttons
      />
      {loading && (
        <div className="spinner-overlay">
          <CircularProgress style={{ color: config.color_spinner }} />
        </div>
      )}

      <button
        // Si el position del close button es: none. El boton pasara a la misma altura que el widgetButton y será transparente, esto para simular el comporamiento del cierre del widget.
        className={`mitrolbutton-close-iframe-${config.position_iframe_close_button} ${buttonsQuantity} ${fullSize ? "expanded-buttons" : "not-expanded-buttons"}`}
        onClick={(event) => {
          event.stopPropagation();
          onClose(event);
        }}
        style={{ display: !buttonUnique ? "block" : "none" }}
      >
        {/* Si es fullSize true no tiene contenido, así no tapa el contenido del WidgetButton */}
        {config.position_iframe_close_button !== "none" ? "X" : ""}
      </button>
    </div>
  );
};

IframeContainer.propTypes = {
  url: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  setLoading: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  config: PropTypes.object.isRequired,
  buttonUnique: PropTypes.bool.isRequired,
  widgetType: PropTypes.string.isRequired,
  buttonStyle: PropTypes.string,
};

export default IframeContainer;

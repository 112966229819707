import React from "react";
import PropTypes from "prop-types";
import ButtonWithIframe from "../ButtonWithIframe/ButtonWithIframe";
import "./VerticalWidget.css";

const VerticalWidget = ({
  config,
  expanded,
  iframeStates,
  handleButtonClick,
  closeIframe,
  buttons,
  loading,
  setLoading,
  iframeExpanded,
  handleButtonHover,
  handleButtonLeave,
  buttonUnique,
}) => {
  return (
    <div
      className={`mitrol-expanded-widget-vertical ${
        expanded ? "expanded" : ""
      }`}
    >
      {buttons?.map((button, index) => {
        return (
          <ButtonWithIframe
            key={index}
            button={button}
            isOpen={iframeStates[index]}
            loading={loading}
            setLoading={setLoading}
            onClick={() => handleButtonClick(index)}
            onClose={(event) => closeIframe(index, event)}
            expanded={expanded}
            onMouseEnter={() => handleButtonHover(index)}
            onMouseLeave={handleButtonLeave}
            config={config}
            buttonUnique={buttonUnique}
            widgetType="vertical"
            fullSize={button.fullSize}
          />
        );
      })}
    </div>
  );
};

VerticalWidget.propTypes = {
  config: PropTypes.object.isRequired,
  expanded: PropTypes.bool.isRequired,
  iframeStates: PropTypes.array.isRequired,
  handleButtonClick: PropTypes.func.isRequired,
  closeIframe: PropTypes.func.isRequired,
  buttons: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  setLoading: PropTypes.func.isRequired,
  iframeExpanded: PropTypes.bool.isRequired,
  handleButtonHover: PropTypes.func.isRequired,
  handleButtonLeave: PropTypes.func.isRequired,
  buttonUnique: PropTypes.bool.isRequired,
};

export default VerticalWidget;

import "./DefaultWidgetButton.css";

const DefaultWidgetButton = () => {
  return (
    <div className="default-widget-button">
      <h2>Configure el widget</h2>
      <p>o</p>
      <p>Revise el parametro ingresado en el URL</p>
    </div>
  );
};

export default DefaultWidgetButton;

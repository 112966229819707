import React from "react";
import IframeContainer from "../../ExpandedWidget/IframeContainer/IframeContainer";

const HorizontalWidgetButton = ({
  rectangleVisible,
  toggleRectangle,
  config,
  loading,
  setLoading,
  expanded,
  setRectangleVisible, // Asegúrate de recibir setRectangleVisible aquí
}) => {
  const handleCloseIframe = () => {
    setLoading(false);
    setRectangleVisible(false);
  };

  return (
    <>
      <div
        className={`widget-rectangle ${!rectangleVisible ? "" : "hidden"}`}
        onClick={toggleRectangle}
        style={{ backgroundColor: config.bg_horizontal }}
      >
        <img
          src={config.img_horizontal}
          alt="Icon"
          style={{ width: 64, height: 29, cursor: "pointer" }}
        />

        <p className="text-rectangle">{config.text_horizontal}</p>
      </div>
      {rectangleVisible && (
        <div className="rectangle-iframe-container visible">

          <IframeContainer
            url={config.url_iframe_horizontal}
            loading={loading}
            setLoading={setLoading}
            onClose={handleCloseIframe} // Usa handleCloseIframe aquí
            config={config}
            buttonUnique={false}
            widgetType="horizontal" // Asegúrate de pasar el widgetType correcto
            buttonStyle={undefined}
          />
        </div>
      )}
    </>
  );
};

export default HorizontalWidgetButton;

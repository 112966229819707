import ExpandedWidget from "../../ExpandedWidget/ExpandedWidget";

const VerticalWidgetButton = ({
  config,
  toggleWidget,
  expanded,
  buttonClassName,
  iconContent,
}) => {
  //Estilo para el resto de los botones
  const buttonStyle = {
    backgroundColor: config.bg_buttonNotExpanded,
    width: !config.vertical_text_box ? config.button_width : "45px",
    height: !config.vertical_text_box ? config.button_height : "45px",
    borderRadius: "50%",
    border: "none",
    cursor: "pointer",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.8)",
    color: "white",
    fontSize: "24px",
    lineHeight: "50px",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "width 0.5s ease, height 0.5s ease, background-color 0.5s",
  };

  const buttonExpandedStyle = {
    backgroundColor: config.bg_buttonExpanded,
    width: !config.vertical_text_box ? config.button_width : "45px",
    height: !config.vertical_text_box ? config.button_height : "45px",
    borderRadius: "50%",
    border: "none",
    cursor: "pointer",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.8)",
    color: "white",
    fontSize: "24px",
    lineHeight: "50px",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "width 0.5s ease, height 0.5s ease, background-color 0.5s",
  };

  return (
    <>
      {config.vertical_text_box && (
        <div
          className="vertical-text-box-container"
          onClick={() => toggleWidget()}
          style={
            !expanded
              ? { backgroundColor: config.bg_buttonNotExpanded }
              : { backgroundColor: config.bg_buttonExpanded }
          }
        >
          <p
            className="vertical-text-box-content"
            style={
              !expanded
                ? { color: config.color_text_box_content_not_expanded }
                : { color: config.color_text_box_content_expanded }
            }
          >
            {config.vertical_text_box_content.length > 32
              ? config.vertical_text_box_content.slice(0, 32) + "..."
              : config.vertical_text_box_content}
          </p>
        </div>
      )}
      <div className={`widget-button ${expanded ? "expanded" : ""}`}>
        <button
          onClick={() => toggleWidget()}
          className={buttonClassName}
          style={expanded ? buttonExpandedStyle : buttonStyle}
        >
          {iconContent}
        </button>
        {expanded ? (
          <ExpandedWidget expanded={expanded} config={config} />
        ) : (
          <ExpandedWidget expanded={expanded} config={config} />
        )}
      </div>
    </>
  );
};

export default VerticalWidgetButton;

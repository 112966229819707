import React from "react";
import PropTypes from "prop-types";
import "./CircularWidget.css";
import IframeContainer from "../IframeContainer/IframeContainer";
import {
  calcularFontSize,
  calcularHeightIcon,
  calcularWidthIcon,
} from "../../../helpers/utils";

const CircularWidget = ({
  config,
  expanded,
  iframeStates,
  handleButtonClick,
  closeIframe,
  buttons,
  loading,
  setLoading,
  iframeExpanded,
  handleButtonHover,
  handleButtonLeave,
  buttonUnique,
}) => {
  const buttonCount = buttons.length;
  const widgetClass =
    buttonCount === 1
      ? "single-mitrolbutton-style"
      : buttonCount === 2
      ? "double-mitrolbutton-style"
      : buttonCount === 3
      ? "triple-mitrolbutton-style"
      : "default-mitrolbutton-style";

  const getButtonStyle = (index) => {
    if (buttonCount === 1) return "one-mitrolbutton-circular";
    if (buttonCount === 2)
      return index === 0
        ? "mitrolbutton-double-first-style"
        : "mitrolbutton-double-second-style";
    if (buttonCount === 3) {
      if (index === 0) return "mitrolbutton-triple-first-style";
      if (index === 1) return "mitrolbutton-triple-second-style";
      return "mitrolbutton-triple-third-style";
    }
    return "default-mitrolbutton-style";
  };

  return (
    <div
      className={`mitrol-expanded-widget-circular ${widgetClass} ${
        expanded ? "expanded" : ""
      }`}
      style={{ backgroundColor: config.bg_widgetExpanded }}
    >
      {buttons.map((button, index) => (
        <div
          className={`mitrol-mitrolbutton-container-circular ${getButtonStyle(
            index
          )} ${expanded ? "fadeIn" : ""} ${
            iframeStates[index] ? "iframe-hover" : ""
          }`}
          onClick={() => handleButtonClick(index)}
          onMouseEnter={() => handleButtonHover(index)}
          onMouseLeave={handleButtonLeave}
          key={index}
        >
          <div
            className="mitrol-mitrolbutton-inside-circular"
            style={{ backgroundColor: config.bg_buttonInsideWidget }}
          >
            <img
              src={button.icon}
              alt="Icon"
              className="mitrolbutton-icon"
              style={{
                width: calcularWidthIcon(button.button_size),
                height: calcularHeightIcon(button.button_size),
              }}
            />
          </div>
          <span
            className="mitrol-titulo-circular"
            style={{
              color: config.color_titleButtons,
              fontFamily: config.font_family,
              fontSize: calcularFontSize(config.font_size),
            }}
          >
            {button.title.length > 16
              ? button.title.slice(0, 16) + "..."
              : button.title}
          </span>
          {iframeStates[index] && (
            <IframeContainer
              url={button.urlRedirect}
              loading={loading}
              setLoading={setLoading}
              onClose={(event) => closeIframe(index, event)}
              config={config}
              buttonUnique={buttonUnique}
              widgetType="circular"
              buttonStyle={getButtonStyle(index)}
            />
          )}
        </div>
      ))}
    </div>
  );
};

CircularWidget.propTypes = {
  config: PropTypes.object.isRequired,
  expanded: PropTypes.bool.isRequired,
  iframeStates: PropTypes.array.isRequired,
  handleButtonClick: PropTypes.func.isRequired,
  closeIframe: PropTypes.func.isRequired,
  buttons: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  setLoading: PropTypes.func.isRequired,
  iframeExpanded: PropTypes.bool.isRequired,
  handleButtonHover: PropTypes.func.isRequired,
  handleButtonLeave: PropTypes.func.isRequired,
  buttonUnique: PropTypes.bool.isRequired,
};

export default CircularWidget;

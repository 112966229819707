import React, { useEffect, useState, useCallback } from "react";
import "./ExpandedWidget.css";
import VerticalWidget from "./VerticalWidget/VerticalWidget";
import VerticalV2Widget from "./VerticalV2Widget/VerticalV2Widget";
import CircularWidget from "./CircularWidget/CircularWidget";




const ExpandedWidget = ({ expanded, config, buttonStyle }) => {

  const isMobileScreen = () => window.innerWidth <= 767;
  const [, setIsMobile] = useState(isMobileScreen());

  const buttonsLength = config.buttons.length;
  const buttons = config.buttons;
  const [iframeStates, setIframeStates] = useState(
    Array(buttonsLength).fill(false)
  );
  const [, setHoveredIndex] = useState(null);
  const [loading, setLoading] = useState(false);
  const [buttonUnique, setButtonUnique] = useState(false);
  const [iframeExpanded, setIframeExpanded] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(isMobileScreen());
    };

    // Agregar un listener para el evento de cambio de tamaño de la pantalla
    window.addEventListener("resize", handleResize);

    // Eliminar el listener al desmontar el componente
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const closeIframe = (index, event) => {
    event.stopPropagation();

    // Cerrar todos los iframes abiertos
    const updatedIframeStates = iframeStates.map((state, i) => {
      if (i === index) {
        return false; // Cerrar el iframe actual
      }
      return state;
    });
    setIframeStates(updatedIframeStates);

    // Detener la carga si se cierra el iframe
    setLoading(false);
  };

  const handleButtonClick = useCallback(
    (index) => {
      // Verificar si el iframe del botón clicado ya está abierto
      const isAlreadyOpen = iframeStates[index];
      const button = buttons[index];

      if (!button.flotante) {
        window.open(button.urlRedirect, "_blank");
        return;
      }

      // Si el iframe está abierto, cerrarlo
      if (isAlreadyOpen) {
        const updatedIframeStates = [...iframeStates];
        updatedIframeStates[index] = false;
        setIframeStates(updatedIframeStates);

        // Detener la carga si se cierra el iframe
        setLoading(false);

        // Actualizar el estado de la expansión de los iframes
        setIframeExpanded(updatedIframeStates.some((state) => state));
      } else {
        // Si el iframe no está abierto, cerrar cualquier otro iframe abierto
        const updatedIframeStates = iframeStates.map((state, i) =>
          i === index ? true : false
        );
        setIframeStates(updatedIframeStates);

        // Actualizar el estado de carga si se está abriendo el iframe
        setLoading(true);

        // Actualizar el estado de la expansión de los iframes
        setIframeExpanded(true);
      }
    },
    [iframeStates, buttons, setIframeStates, setLoading, setIframeExpanded]
  );

  // Usar un estado para controlar la inicialización
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!initialized) {
      if (
        (buttons.length === 1 && config.widget_type === "vertical") ||
        (buttons.length === 1 && config.widget_type === "vertical-v2")
      ) {
        handleButtonClick(0);
        setButtonUnique(true);
      }
      setInitialized(true);
    }
  }, [buttons.length, config, handleButtonClick, initialized]);

  const handleButtonHover = (index) => {
    if (!iframeStates[index]) {
      setHoveredIndex(index);
    }
  };

  const handleButtonLeave = () => {
    setHoveredIndex(null);
  };


  if (config.widget_type === "vertical") {
    return (
      <VerticalWidget
        config={config}
        expanded={expanded}
        iframeStates={iframeStates}
        handleButtonClick={handleButtonClick}
        closeIframe={closeIframe}
        buttons={buttons}
        loading={loading}
        setLoading={setLoading}
        buttonStyle={buttonStyle}
        iframeExpanded={iframeExpanded}
        handleButtonHover={handleButtonHover}
        handleButtonLeave={handleButtonLeave}
        buttonUnique={buttonUnique}
      />
    );
  } else if (config.widget_type === "vertical-v2") {
    return (
      <VerticalV2Widget
        config={config}
        expanded={expanded}
        iframeStates={iframeStates}
        handleButtonClick={handleButtonClick}
        closeIframe={closeIframe}
        buttons={buttons}
        loading={loading}
        setLoading={setLoading}
        iframeExpanded={iframeExpanded}
        handleButtonHover={handleButtonHover}
        handleButtonLeave={handleButtonLeave}
        buttonUnique={buttonUnique}
      />
    );
  } else if (config.widget_type === "circular") {
    return (
      <CircularWidget
        config={config}
        expanded={expanded}
        iframeStates={iframeStates}
        handleButtonClick={handleButtonClick}
        closeIframe={closeIframe}
        buttons={buttons}
        loading={loading}
        setLoading={setLoading}
        iframeExpanded={iframeExpanded}
        handleButtonHover={handleButtonHover}
        handleButtonLeave={handleButtonLeave}
        buttonUnique={buttonUnique}
      />
    );
  } else {
    return null;
  }
};

export default ExpandedWidget;

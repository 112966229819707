export const calcularFontSize = (fontSizeJson) => {
  switch (fontSizeJson) {
    case 'small':
      return "12px";

    case 'medium':
      return "14px";

    case 'large':
      return "16px";

    default:
      return "12px";
  }
};

export const calcularHeightIcon = (sizeIconJson) => {
  switch (sizeIconJson) {
    case "large":
      return "45px";
    case "medium":
      return "35px";
    case "small":
      return "30px";
    default:
      return "45px";
  }
};

export const calcularWidthIcon = (sizeIconJson) => {
  switch (sizeIconJson) {
    case "large":
      return "45px";
    case "medium":
      return "35px";
    case "small":
      return "30px";
    default:
      return "45px";
  }
};

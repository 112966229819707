import ExpandedWidget from "../../ExpandedWidget/ExpandedWidget";

const VerticalV2WidgetButton = ({
  config,
  expanded,
  toggleWidget,
  buttonClassName,
  iconContent,
  gradientColors
}) => {
  const buttonStyleV2 = {
    backgroundColor: config.bg_buttonNotExpanded,
    width: "88px",
    height: "88px",
    borderRadius: "50%",
    border: "none",
    cursor: "pointer",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.8)",
    color: "white",
    fontSize: "24px",
    lineHeight: "50px",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 2,
    transition: "width 0.5s ease, height 0.5s ease, background-color 0.5s",
  };

  const buttonExpandedStyleV2 = {
    backgroundColor: config.bg_buttonExpanded,
    width: "88px",
    height: "88px",
    borderRadius: "50%",
    border: "none",
    cursor: "pointer",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.4)",
    color: "white",
    fontSize: "24px",
    lineHeight: "50px",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "sans-serif",
    zIndex: 3,
    transition: "width 0.5s ease, height 0.5s ease, background-color 0.5s",
  };

  const gradientStyle = {
    backgroundImage: `linear-gradient(to right, ${gradientColors[0]}, ${gradientColors[1]})`,
  };

  return (
    <>
      <div
        className="vertical-v2-text-box-container"
        onClick={() => toggleWidget()}
        style={
          !expanded
          ? { ...gradientStyle, backgroundColor: config.bg_buttonNotExpanded }
          : { ...gradientStyle, backgroundColor: config.bg_buttonExpanded }
        }
      >
        <div>
          <p
            className="vertical-v2-title-text-box"
            style={
              !expanded
                ? {
                    color: config.color_text_box_content_not_expanded,
                  }
                : { color: config.color_text_box_content_expanded }
            }
          >
            {config.text_box_title_content.length > 32
              ? config.text_box_title_content.slice(0, 32) + "..."
              : config.text_box_title_content}
          </p>
          <p
            className="vertical-v2-subtitle-text-box"
            style={
              !expanded
                ? {
                    color: config.color_text_box_content_not_expanded,
                  }
                : { color: config.color_text_box_content_expanded }
            }
          >
            {config.text_box_subtitle_content.length > 50
              ? config.text_box_subtitle_content.slice(0, 32) + "..."
              : config.text_box_subtitle_content}
          </p>
        </div>
      </div>
      <div
        className={`widget-mitrolbutton-vertical-v2 ${expanded ? "expanded" : ""}`}
      >
        <button
          onClick={() => toggleWidget()}
          className={buttonClassName}
          style={expanded ? buttonExpandedStyleV2 : buttonStyleV2}
        >
          {iconContent}
        </button>
      </div>

      {expanded ? (
        <ExpandedWidget expanded={expanded} config={config}/>
      ) : (
        <ExpandedWidget expanded={expanded} config={config}/>
      )}
    </>
  );
};

export default VerticalV2WidgetButton;

import React, { useState } from "react";
import "./WidgetButton.css";
import HorizontalWidgetButton from "./HorizontalWidgetButton/HorizontalWidgetButton";
import VerticalWidgetButton from "./VerticalWidgetButton/VerticalWidgetButton";
import CircularWidgetButton from "./CircularWidgetButton/CircularWidgetButton";
import VerticalV2WidgetButton from "./VerticalV2WidgetButton/VerticalV2WidgetButton";
import DefaultWidgetButton from "./DefaultWidgetButton/DefaultWidgetButton";
import { calcularHeightIcon, calcularWidthIcon } from "../../helpers/utils";

const WidgetButton = ({ config }) => {
  const [expanded, setExpanded] = useState(false);
  const [currentIcon, setCurrentIcon] = useState(config.icon_notExpanded);
  const [rectangleVisible, setRectangleVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [, setIframeLoaded] = useState(false);

  const iconStyle = {
    width: calcularWidthIcon(!expanded ? config.icon_notExpanded.size : config.icon_expanded.size),
    height: calcularHeightIcon(!expanded ? config.icon_notExpanded.size : config.icon_expanded.size),
    transition: "width 0.5s ease, height 0.5s ease",
  };

  const iconStyleV2 = {
    width: "88px",
    height: "88px",
    borderRadius: 50,
    transition: "width 0.5s ease, height 0.5s ease",
  };

  let iconContent;

  if (currentIcon.type === "image") {
    iconContent = (
      <img
        src={currentIcon.url}
        alt="Icon"
        style={config.widget_type === "vertical-v2" ? iconStyleV2 : iconStyle}
      />
    );
  } else {
    iconContent = currentIcon.content;
  }

  const toggleWidget = () => {
    if (config.buttons.length > 1) {
      setExpanded(!expanded);
      setCurrentIcon(expanded ? config.icon_notExpanded : config.icon_expanded);
      setRectangleVisible(!rectangleVisible);
    } else {
      setExpanded(!expanded);
      setCurrentIcon(expanded ? config.icon_notExpanded : config.icon_expanded);
    }
  };

  const toggleRectangle = () => {
    setRectangleVisible((prevState) => !prevState);
    setLoading(true);
  };

  const handleIframeLoad = () => {
    setIframeLoaded(true);
    setLoading(false);
  };

  const buttonClassName = `widget-mitrolbutton-toggle ${expanded ? "expanded" : ""}`;

  return (
    <>
      {config.widget_type === "horizontal" ? (
        <HorizontalWidgetButton
          rectangleVisible={rectangleVisible}
          toggleRectangle={toggleRectangle}
          config={config}
          loading={loading}
          setLoading={setLoading}
          expanded={expanded}
          handleIframeLoad={handleIframeLoad}
          setRectangleVisible={setRectangleVisible} // Pasa setRectangleVisible aquí
        />
      ) : (
        <div style={{ display: "flex", alignItems: "flex-end" }}>
          {config.widget_type === "default" && (
            <DefaultWidgetButton/>
          )}

          {config.widget_type === "vertical" && (
            <VerticalWidgetButton
              config={config}
              toggleWidget={toggleWidget}
              expanded={expanded}
              buttonClassName={buttonClassName}
              iconContent={iconContent}
            />
          )}

          {config.widget_type === "vertical-v2" && (
            <VerticalV2WidgetButton
              config={config}
              expanded={expanded}
              toggleWidget={toggleWidget}
              buttonClassName={buttonClassName}
              iconContent={iconContent}
              gradientColors={config.gradientColors}
            />
          )}

          {config.widget_type === "circular" && (
            <CircularWidgetButton
              config={config}
              toggleWidget={toggleWidget}
              expanded={expanded}
              buttonClassName={buttonClassName}
              iconContent={iconContent}
            />
          )}
        </div>
      )}
    </>
  );
};

export default WidgetButton;

import ExpandedWidget from "../../ExpandedWidget/ExpandedWidget";

const CircularWidgetButton = ({
  config,
  toggleWidget,
  expanded,
  iconContent,
}) => {
  const buttonStyle = {
    backgroundColor: config.bg_buttonNotExpanded,
    color: config.icon_expanded.color,
    width: config.button_width,
    height: config.button_height,
    borderRadius: "50%",
    border: "none",
    cursor: "pointer",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.8)",
    fontSize: "24px",
    lineHeight: "50px",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 2,
    transition: "width 0.5s ease, height 0.5s ease, background-color 0.5s",
  };

  const buttonExpandedStyle = {
    backgroundColor: config.bg_buttonExpanded,
    color: config.icon_expanded.color,
    width: config.button_width,
    height: config.button_height,
    borderRadius: "50%",
    border: "none",
    cursor: "pointer",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.8)",
    fontSize: "24px",
    lineHeight: "50px",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 3,
    transition: "width 0.5s ease, height 0.5s ease, background-color 0.5s",
  };


  return (
    <div className="widget-button">
      <button
        onClick={toggleWidget}
        className={`widget-mitrolbutton-toggle ${expanded ? "expanded" : ""}`}
        style={expanded ? buttonExpandedStyle : buttonStyle}
      >
        {iconContent}
      </button>

      {expanded ? (
        <ExpandedWidget expanded={expanded} config={config} />
      ) : (
        <ExpandedWidget style={{ display: "none" }} config={config}/>
      )}
    </div>
  );
};

export default CircularWidgetButton;

import React from "react";
import PropTypes from "prop-types";
import IframeContainer from "../IframeContainer/IframeContainer";
import "./ButtonWithIframe.css";
import {
  calcularFontSize,
  calcularHeightIcon,
  calcularWidthIcon,
} from "../../../helpers/utils";

const ButtonWithIframe = ({
  button,
  isOpen,
  loading,
  setLoading,
  onClick,
  onClose,
  expanded,
  onMouseEnter,
  onMouseLeave,
  config,
  buttonUnique,
  widgetType,
  buttonStyle
}) => {
  const armarTituloBoton = (button) => {
    switch (config.font_size) {
      case "small": {
        if (button.title.length > 18) {
          return button.title.slice(0, 18) + "...";
        } else {
          return button.title;
        }
      }
      case "medium":
        if (button.title.length > 14) {
          return button.title.slice(0, 14) + "...";
        } else {
          return button.title;
        }
      case "large":
        if (button.title.length > 13) {
          return button.title.slice(0, 13) + "...";
        } else {
          return button.title;
        }
      default:
        throw new Error(
          'Revise el valor ingresado para "font_size" en el archivo de configuración'
        );
    }
  };
  return (
    <div
      className={`mitrol-mitrolbutton-container-vertical ${
        expanded ? "fadeIn" : ""
      } ${isOpen ? "iframe-hover" : ""}`}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={{ width: 45, height: 45 }}
    >
      <div
        className="mitrol-mitrolbutton-inside-vertical"
        style={{ display: !buttonUnique ? "flex" : "none" }}
      >
        <div
          className="text-box-inside-widget"
          style={{ backgroundColor: config.bg_text_box_inside_widget }}
        >
          <p
            className="title-text-box-inside-widget"
            style={{
              color: config.color_title_text_box_inside_widget,
              fontFamily: config.font_family,
              fontSize: calcularFontSize(config.font_size),
            }}
          >
            {armarTituloBoton(button)}
          </p>
        </div>
        <div className="iconmitrol-mitrolbutton-container">
          <img
            src={button.icon}
            alt="Icon"
            style={{
              width: calcularWidthIcon(button.button_size),
              height: calcularHeightIcon(button.button_size),
              marginLeft:4,
              marginTop:2,
            }}
          />
        </div>
      </div>
      {isOpen && (
        <IframeContainer
          url={button.urlRedirect}
          loading={loading}
          setLoading={setLoading}
          onClose={onClose}
          config={config}
          buttonUnique={buttonUnique}
          widgetType={widgetType}
          fullSize={button.fullSize}
        />
      )}
    </div>
  );
};

ButtonWithIframe.propTypes = {
  button: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  setLoading: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  expanded: PropTypes.bool.isRequired,
  onMouseEnter: PropTypes.func.isRequired,
  onMouseLeave: PropTypes.func.isRequired,
  config: PropTypes.object.isRequired,
  buttonUnique: PropTypes.bool.isRequired,
  widgetType: PropTypes.string.isRequired,
};

export default ButtonWithIframe;
